import { createStore } from 'vuex';
import accounting from './accounting';
import consolidation from './consolidation';
import expenseReport from './expenseReport';
import global from './global';

export default createStore({
  state: {
    selectedModule: null
  },
  getters: {
    selectedModule: state => state.selectedModule
  },
  mutations: {
    setSelectedModule(state, moduleName) {
      state.selectedModule = moduleName;
    }
  },
  modules: {
    accounting,
    consolidation,
    expenseReport,
    global,
  }
});